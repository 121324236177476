import React, { Component } from 'react'
import './style/broou-ad.css'
import I18n from "i18n-js";
// import * as Hammer from "hammerjs";

class BroouAd extends Component {
  componentDidMount(){
    // this.createHeader()
  }

  componentDidUpdate(){
    // this.createHeader()
  }

  createHeader() {

  }

  updateDimensions(winWidth, winHeight) {
    window.margin = {};
    window.margin.top = 0;
    window.margin.right = 0;
    window.margin.left = 0;
    window.margin.bottom = 0;

    // debugger;
    window.addEventListener("orientationchange", function(event){
      switch(window.orientation) {
        case -90 || 90:
          // console.log('landscape');
          window.width = winWidth - window.margin.left - window.margin.right;
          window.height = winHeight - window.margin.top - window.margin.bottom;
          break;
        default:
          // console.log('portrait');
          window.height = winWidth - window.margin.left - window.margin.right;
          window.width = winHeight - window.margin.top - window.margin.bottom;
          break;
      }
    });

    window.width = winWidth - window.margin.left - window.margin.right;
    window.height = winHeight - window.margin.top - window.margin.bottom;

    // var height = winWidth - window.margin.left - window.margin.right;
    // var width = winHeight - window.margin.top - window.margin.bottom;

    // console.log("width = " + width)
    // console.log("height = " + height)
    return [window.width, window.height]
  }

  render() {
    // console.log("Primary Height = " + window.innerHeight)
    // console.log("Primary Width = " + window.innerWidth)
    var width = this.updateDimensions(window.innerWidth, window.innerHeight)[0];
    var height = this.updateDimensions(window.innerWidth, window.innerHeight)[1];

    var probability = function(n) {
      // console.log(Math.random())
      return !!n && Math.random() <= n;
    };
    
    var image_banner = ( probability(0.5) ) ? { 
      url: "https://shop.broou.com/?cupom=BROOUADCAMPAIGN33", 
      img: "../crazy_asset_1_2x_33.png"
    } : {
      url: "https://shop.broou.com/?cupom=BROOUADCAMPAIGN33", 
      img: "../crazy_asset_1_2x_33.png"
    }

    return(
        <div className="broou-ad">
          <a href={ image_banner.url }>
            <img src={ image_banner.img }/>
          </a>
        </div>
    );
  }
}
export default BroouAd
