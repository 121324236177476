import React, { Component } from 'react';
import './style/App.css';
import './i18n.js';
import I18n from "i18n-js";
import * as d3 from "d3-3";
import * as chartFunction from './chartFunctions.js';
import HeaderData from './headerData';
import BroouAd from './broouAd';

class LineChart extends Component {
  constructor(props){
    super(props)
    this.state = {
        data: '',
        hourData: {},
        id: this.props.match.params.id,
        spotname: '',
        width: this.updateDimensions(window.innerWidth, window.innerHeight)[0],
        height: this.updateDimensions(window.innerWidth, window.innerHeight)[1],
        params: this.convertParams(this.props.location.search)
    }
    if (this.state.params.locale) {
      this.state.params.locale = this.state.params.locale
    } else {
      this.state.params.locale = this.props.match.params.locale
    }
    if (this.state.params.locale) {
      if (this.state.params.locale.indexOf('en') > -1) {
        I18n.locale = 'en'
      }
    }
  }

  convertParams(params) {
    var obj = {};
    params.replace(/\?/,'').split('&').forEach(function(el){
      var data = el.split('=')[1];
      if (data == 'feet' || data == 'ft') {
        obj[el.split('=')[0]] = 'ft'
      } else {
        obj[el.split('=')[0]] = el.split('=')[1]
      }
    })
    return obj
  }

  fetchForecastData() {
    window.fetchThis = this;
    if (window.fetchThis.props.match.params.id !== "index.html") {
      var url = 'https://broou.xyz/show/' + this.state.id + '.json';
      // var url = 'https://localhost:3000/show/' + this.state.id + '.json';
      fetch(url, {method: 'get', mode: 'cors'})
        .then((response) => {
          return response.json();
        })
        .then(function(data) {
          window.fetchThis.setState({
            data: data[1],
            spotname: data[0].spotname
          });
        })
    }
  }

  componentWillMount(){
    // this.createGraphChart()
    // this.fetchForecastData()
  }

  componentDidMount(){
    // this.createGraphChart()
    this.fetchForecastData()
  }

  componentDidUpdate(){
    this.createGraphChart()
  }

  updateDimensions(winWidth, winHeight) {
    window.margin = {};
    window.margin.top = 0;
    window.margin.right = 0;
    window.margin.left = 0;
    window.margin.bottom = 0;

    window.width = winWidth - window.margin.left - window.margin.right;
    window.height = winHeight - window.margin.top - window.margin.bottom;

    // var height = winWidth - window.margin.left - window.margin.right;
    // var width = winHeight - window.margin.top - window.margin.bottom;

    return [window.width, window.height]
  }

  onTouchMove(x) {
    var xPos, yPos;
    if (typeof(x) === 'undefined') {
      if (d3.event.type === "click" || d3.event.type === "mousemove") {
        xPos = d3.event.clientX;
        yPos = d3.event.clientY;
      } else {
        xPos = d3.touches(this)[0][0];
        yPos = d3.touches(this)[0][1];
      }
    } else {
      xPos = x;
    }

    var d = window.xdata[~~window.touchScale(xPos)];

    d3.select('.wind.dir svg.header')
      .transition()
      .duration(50)
      .attr("style", "transform: rotate(" + d.dwnd + "deg);")
    d3.select('.wind.dir .dir-wind').text(chartFunction.getTextDir(d.dwnd))
    d3.select('.wind.dir .sec')
      .text(d.iwnd.toFixed(0))

    d3.select('.wave.dir .dir').text(chartFunction.getTextDir(d.dpbro))
    d3.select('.wave.dir svg.header')
      .transition()
      .duration(50)
      .attr("style", "transform: rotate(" + d.dpbro + "deg);")
    d3.select('.wave.dir .sec').text(d.tpbro.toFixed(0))

    d3.select('.wave.hsbro span.hs').text(d.hsbro.toFixed(1))

    d3.select('.wave.hsnoaa span.hs').text(d.hsnoaa.toFixed(1))

    // var div = d3.select("svg.graph").append("div")
    //             .attr("class", "tooltip")
    //             .style("opacity", 0);

    // div.html(d.date + "<br/>"  + d.hsnoaa)
    //             .style("left", (xPos) + "px")
    //             .style("top", (yPos - 28) + "px");

    // window.tooltip = d3.select(this)
    // window.tooltip.append("div").attr('class', 'tooltip').attr('x', window.x(new Date(d.date)))
    //               .attr('y', window.y(d.hsnoaa) - 10)
    //               .html(new Date(d.date))
    // var g = d3.select(this); // The node
    // var div = d3.select("body").append("div")
    //         .attr('pointer-events', 'none')
    //         .attr("class", "tooltip")
    //         .style("opacity", 1)
    //         .html(new Date(d.date))
    //         .style("left", (window.x(new Date(d.date) + "px")))
    //         .style("top", (window.y(d.hsnoaa) - 20 + "px"));

    // d3.select('.line-min').style('opacity', '0');
    d3.select('.line-max').style('opacity', '0');
    window.waveHeight.style('opacity', '1');
    window.wavePer.style('opacity', '1');
    // window.minBox.style('opacity', '0');
    // window.minText.style('opacity', '0');
    window.maxBox.style('opacity', '0');
    window.maxText.style('opacity', '0');

    window.waveHeight.style('display', 'block');
    window.wavePer.style('display', 'block');

    var hstop, hsdown;
    if (d.hsnoaa > d.hsbro) {
      hstop = d.hsnoaa;
      hsdown = d.hsbro;
    } else {
      hstop = d.hsbro;
      hsdown = d.hsnoaa;
    }

    window.tooltip.attr({
      x : window.x(new Date(d.date)) - 27,
      y : window.y(hstop) - 50
    })
    .classed('orientation', true)
    .style('display', 'block');

    window.tooltipText.attr({
                        x : window.x(new Date(d.date)) - 15,
                        y : window.y(hstop) - 32
                      })
                      .text(window.dtformat(new Date(d.date)))
                      .style('fill', 'white')
                      .style('font-size', '12')
                      .style('display', 'block')

    window.tooltipTri
          .attr("points",
          (window.x(new Date(d.date)) - 6) + "," + (window.y(hstop) - 20) +
          "," + (window.x(new Date(d.date)) + 6) + "," + (window.y(hstop) - 20) +
          "," + window.x(new Date(d.date)) + "," + (window.y(hstop) - 14) )
          .style('display', 'block');

    window.locline.attr({
      x1 : window.x(new Date(d.date)),
      x2 : window.x(new Date(d.date)),
      y1 : window.y(hsdown) + 8,
      y2 : window.height
    })
    .style('display', 'block');

    if (d.broeqnoaa) {
    } else if ((hstop - hsdown) > 0.1) {
      window.loclineNoaa.attr({
        x1 : window.x(new Date(d.date)),
        x2 : window.x(new Date(d.date)),
        y1 : window.y(hstop) + 8,
        y2 : window.y(hsdown) - 8
      }).style('display', 'block');
    } else {
      window.loclineNoaa.style('display', 'none');
    }

    window.locator.attr({
      cx : window.x(new Date(d.date)),
      cy : window.y(d.hsbro)
    })
    .style('display', 'block');

    window.locatorNoaa.attr({
      cx : window.x(new Date(d.date)),
      cy : window.y(d.hsnoaa)
    })
    .style('display', 'block');
    
    return true;
  }

  createGraphChart(){
    // debugger;
    // d3.selectAll(".graph circle").remove();
    const data = this.state.data;
    const svg = d3.select(".graph"),
        width = +svg.attr("width"),
        height = +svg.attr("height");

    if (typeof(data) === "object") {
      const spotdata = data.forecast;
      window.xdata = [];
      for( var i in spotdata.hs1 ) {
        if ( data.forecast.isequal ) {
          var hsbro = spotdata.hs2[i];
          var broeqnoaa = true;
        } else {
          var hsbro = spotdata.hs1[i];
          var broeqnoaa = false;
        }
        window.xdata.push( {
                              date: +i,
                              hsbro: chartFunction.waveType(this.state.params.wave, hsbro),
                              dpbro: spotdata.dp2[i],
                              tpbro: spotdata.tp1[i],
                              hsnoaa: chartFunction.waveType(this.state.params.wave, spotdata.hs2[i]),
                              airtmp: spotdata.airt[i],
                              iwnd: spotdata.iwnd[i],
                              dwnd: chartFunction.windType(this.state.params.wind, spotdata.dwnd[i]),
                              broeqnoaa: broeqnoaa
                           } );
      }

      var xExtent = d3.extent(window.xdata, function(d,i) { return d.date });
      var yExtentMin = d3.extent(window.xdata, function(d,i) { return Math.min(d.hsbro, d.hsnoaa); });
      var yExtentMax = d3.extent(window.xdata, function(d,i) { return Math.max(d.hsbro, d.hsnoaa); });
      var yExtent = [0.0, Math.ceil(yExtentMax[1] + 0.5)];

      var yExtentMinTp = d3.extent(window.xdata, function(d,i) { return Math.min(d.tpbro); });
      var yExtentMaxTp = d3.extent(window.xdata, function(d,i) { return Math.max(d.tpbro); });
      var yExtentTp = [0, Math.ceil(yExtentMaxTp[1] + 2)];
      // var yExtent = [0, yExtentMax[1] + 0.5];

      window.x = d3.time.scale().domain(xExtent);
      window.y = d3.scale.linear().domain(yExtent);
      // debugger
      window.yTp = d3.scale.linear().domain(yExtentTp);

      //initialize axis
    //   var xAxis = d3.svg.axis().scale(x).tickSize(6, 0)
    // .tickFormat(d3.time.format("%a %d")).orient('bottom');
      if (this.state.params.locale) {
        if (this.state.params.locale.indexOf("en") > -1) {
          var localize = d3.locale(window.en)
        } else {
          var localize = d3.locale(window.bra)
        }
      } else {
        var localize = d3.locale(window.bra)
      }

      var xAxis = d3.svg.axis().scale(window.x).ticks(d3.time.days, 1)
                        .orient('bottom').tickSize(height).tickFormat(localize.timeFormat("%a %d"));
      var yAxis = d3.svg.axis().scale(window.y).orient('right');
      var yAxisTp = d3.svg.axis().scale(window.yTp).orient('left');

      //the path generator for the line chart
        //
      // var defs = svg.append("defs")
      var pattern = svg.append("pattern")
          .attr({ id:"hash4_4", width:"4", height:"8", patternUnits:"userSpaceOnUse", patternTransform:"rotate(-45)"})
          .append("rect")
          .attr({ width:"4", height:"6", transform:"translate(0,0)",fill:"rgba(0, 175, 230, 0.7)" });

      var pattern = svg.append("pattern")
          .attr({ id:"hash4_5", width:"4", height:"7", patternUnits:"userSpaceOnUse", patternTransform:"rotate(-45)"})
          .append("rect")
          .attr({ width:"4", height:"8", transform:"translate(0,0)",fill:"rgba(255, 255, 255, 0.4)" });

      // var defs = svg.append("defs")
      // var pattern = svg.append("pattern")
      //     .attr({ id:"hash4_5", width:"4", height:"4", patternUnits:"userSpaceOnUse", patternTransform:"rotate(45)"})
      //     .append("rect")
      //     .attr({ width:"15", height:"15", transform:"translate(0,0)", fill:"rgb(170, 170, 170)" });
// debugger
      var line = d3.svg.area()
        .x(function(d) { return window.x(new Date(d.date)) })
        .y(function(d) { return window.y(d.hsbro) })
        .y1(function(d) { return height })
        .interpolate('basis')

      var line2 = d3.svg.area()
        .x(function(d) { return window.x(new Date(d.date)) })
        .y(function(d) { return window.y(d.hsnoaa) })
        .y1(function(d) { return height })
        .interpolate('basis')


      var lineTp = d3.svg.line()
        .x(function(d) { return window.x(new Date(d.date)) })
        .y(function(d) { return window.yTp(d.tpbro) })
        // .interpolate('basis')

      //
      // var line3 = d3.svg.area()
      //   .x(function(d) { return window.x(new Date(d.date)) })
      //   .y(function(d) { return window.y(d.hsbro) })
      //   .y1(function(d) { return height })
      //   .interpolate('basis')

      // var lineMin = d3.svg.line()
      //   .x(function(d) { return window.x(new Date(d.date)) })
      //   .y(function(d) { return window.y(yExtentMin[0] - 0.02) })

      var lineMax = d3.svg.line()
        .x(function(d) { return window.x(new Date(d.date)) })
        .y(function(d) { return window.y(yExtentMax[1] + 0.02) })

      window.touchScale = d3.scale.linear().domain([0,width]).range([0,window.xdata.length-1]).clamp(true);
      //initialize svg
      var chartWrapper = svg.append('g').classed('orientation', true);
      // var chartWrapper2 = svg.append('g');
      // var chartWrapper = svg.append('g');
      var path2 = chartWrapper.append('path').datum(window.xdata).classed('line-noaa', true);
      var path_line3 = chartWrapper.append('path').datum(window.xdata).classed('line-3', true);
      var path = chartWrapper.append('path').datum(window.xdata).classed('line', true);
      var pathTp = chartWrapper.append('path').datum(window.xdata).classed('lineTp', true)
                               .style("stroke", "red")
                               .style("fill", "transparent").attr('stroke-width', '2px');
      window.path = path
      var path3 = chartWrapper.append('path').datum(window.xdata).classed('line-min', true);
      var path4 = chartWrapper.append('path').datum(window.xdata).classed('line-max', true);
      // var path2 = chartWrapper2.append('path2').datum(window.xdata).classed('line', true);
      // chartWrapper.append('g').classed('x axis', true).style("stroke", "gray").style("fill", "gray");
      // chartWrapper.append('g').classed('y axis', true);
      chartWrapper.append('g').classed('x axis', true).style("stroke", "white").style("fill", "white");
      chartWrapper.append('g').classed('y axis', true);

      // Tp axis
      chartWrapper.append('g').classed('yTp axis', true)
                  .style("stroke", "red").style("fill", "red").attr('stroke-width', '1px')
                  .attr("transform", "translate(" + width + " ,0)")	;

      // chartWrapper.append("g")
      //   .attr("class", "y axis")
      //   .attr("transform", "translate(120 ,30)")
      //   .style("fill", "red")
      //   .call(yAxisTp);
      // debugger;

      window.x.range([0, width]);
      window.y.range([height, 0]);
      window.yTp.range([height, 0]);
      //update svg elements to new dimensions
      svg
        .attr('width', width + window.margin.right + window.margin.left)
        .attr('height', height + window.margin.top + window.margin.bottom);
      chartWrapper.attr('transform', 'translate(' + window.margin.left + ',' + window.margin.top + ')');

      // path.classed('diagonal-stripe-2', true);

      //update the axis and line
      xAxis.scale(window.x);
      yAxis.scale(window.y.range([height, 0]));
      yAxisTp.scale(window.yTp.range([height, 0]));

      var h = - 5
      var h2 = height - 25
      svg.select('.x.axis')
          .attr('transform', 'translate(0,' + h + ')')
          .call(xAxis)
          .selectAll("text")
          .attr("x", '8%')
          .attr("y", h2)
          .style("text-anchor", 'null');

      svg.select('.y.axis')
         .attr('transform', 'translate(0,0)')
         .call(yAxis.tickValues(chartFunction.range(yExtent[0], yExtent[1], this.state.params.wave == 'ft' ? 1 : 0.5)))
         .selectAll("text")
         .attr("x", '2%')
         .attr("y", '0')
         .attr("fill", 'white')
         .style("text-anchor", 'start')

       svg.select('.yTp.axis')
          .attr('transform', "translate(" + (width - 30) + " ,0)")
          .call(yAxisTp)
          .selectAll("text")
          .attr("x", '10')
          .attr("y", '0')
          .attr("fill", 'white')
          .style("text-anchor", 'start')

      svg.select('.yTp path')[0][0].style.display = 'none'
      var yticksTp = svg.selectAll('.yTp .tick line')
      yticksTp.each(function(tick) {
        this.style.display = 'none'
      })

      svg.selectAll('.yTp .tick text').each(function(tick) {
        this.style.fill = 'red'
        if (this.innerHTML.length === 1) {
          d3.select(this).attr("x", "16")
        }
      })
      svg.selectAll('.yTp .tick')[0][yticksTp[0].length - 1].style.display = 'none'
      svg.select('.yTp .tick')[0][0].style.display = 'none'

      svg.select('.y .tick')[0][0].style.display = 'none'
      var yticks = svg.selectAll('.y .tick')
      var ytick = yticks[0][yticks[0].length - 1]
      d3.select(ytick).select('text').attr("y", '10');
      d3.select(ytick).select('line').attr("y2", '5').attr("y1", '5');

      var hora = new Date(xExtent[0]).getUTCHours();
      if (hora == 0) {
        document.querySelectorAll('.x.axis .tick line')[0].style.visibility = "hidden";
      } else {
        var ticks = svg.selectAll('.x .tick')
        var count = 0
        ticks.each(function(data){
          var tick = d3.select(this);
          if (count == ticks.size()-1) {
            // tick.attr("transform", 'translate(100,' + h + ')');
            tick.selectAll('text').attr("x", '6%');
          }
          count += 1
        })
      }

      if (!this.state.data.forecast.isequal) {
        path.attr('d', line).attr({fill:"url(#hash4_4)"}) //.attr('fill', "rgb(20, 159, 224)"); //.attr("fill", "url(#diagonal-stripe-2)");
        path_line3.attr('d', line).attr({fill:"url(#hash4_5)"}) //.attr('fill', "rgb(20, 159, 224)"); //.attr("fill", "url(#diagonal-stripe-2)");
      }
      path2.attr('d', line2) //.attr('fill', "rgb(20, 159, 224)"); //.attr("fill", "url(#diagonal-stripe-2)");
      // path3.attr('d', lineMin).style('display', 'none')
      path4.attr('d', lineMax).style('display', 'none')

      pathTp.attr('d', lineTp);

      var centerOfData = window.xdata[Math.round(window.xdata.length/2)]
      window.waveHeight = svg.append("text").attr("x", 150)
                  .classed('orientation', true)
                  .attr("y", 20)
                  .style("font-size", "20px")
                  .style("text-anchor", "end")
                  .style("font-weight", "900")
                  .style("fill", "rgba(170, 170, 170, 0.5)")
                  .text(I18n.t('wave_title') + " (" + chartFunction.wavePre(this.state.params.wave) + ")")
                  .style('display', 'block')
                  
      window.wavePer = svg.append("text").attr("x", width - 7)
                  .classed('orientation', true)
                  .attr("y", 20)
                  .style("font-size", "20px")
                  .style("text-anchor", "end")
                  .style("font-weight", "900")
                  .style("fill", "red")
                  .text(I18n.t('wave_period'))
                  .style('display', 'block')      

      window.locatorNoaa = chartWrapper.append('circle')
                      .classed('orientation', true)
                      .attr('r', 10)
                      .attr('fill', 'rgb(170, 170, 170)')
                      .attr('stroke', 'white')
                      .attr('stroke-width', '5px')
                      .attr({
                        cx : window.x(new Date(centerOfData.date)),
                        cy : window.y(centerOfData.hsnoaa)
                      })
                      .style('display', 'none')

      window.locator = chartWrapper.append('circle')
                            .classed('orientation', true)
                            .attr('r', 10)
                            .attr('fill', 'rgb(20, 159, 224)')
                            .attr('stroke', 'white')
                            .attr('stroke-width', '5px')
                            .attr({
                              cx : window.x(new Date(centerOfData.date)),
                              cy : window.y(centerOfData.hsbro)
                            })
                            .style('display', 'none')

      window.dtformat = localize.timeFormat("%a %H:%M");

      window.tooltip = chartWrapper.append('rect')
                      .classed('orientation', true)
                      .attr('class', 'd3-tip')
                      .attr('width', 75)
                      .attr('height', 30)
                      .attr({
                        x : window.x(new Date(centerOfData.date)) - 27,
                        y : window.y(centerOfData.hsnoaa) - 50
                      })
                      .style('fill', 'rgba(50, 50, 50, 1)')
                      .style('display', 'none')

      window.tooltipText = chartWrapper.append('text')
                      .classed('orientation', true)
                      .attr({
                        x : window.x(new Date(centerOfData.date)) - 20,
                        y : window.y(centerOfData.hsnoaa) - 29
                      })
                      .text(window.dtformat(new Date(centerOfData.date)))
                      .style('fill', 'white')
                      .style('font-weight', '900')
                      .style('display', 'none')

      window.tooltipTri = chartWrapper.append("polygon")
                        .classed('orientation', true)       // attach a polygon
                        .style("fill", "rgba(50, 50, 50, 1)")     // remove any fill colour

      window.maxBox = chartWrapper.append('rect')
                      .classed('orientation', true)
                      .attr('class', 'minmaxbox')
                      .attr('width', '75px')
                      .attr('height', '25px')
                      .attr({
                        x : window.x(new Date(window.xdata[window.xdata.length - 34].date)),
                        y : window.y(yExtentMax[1]) - 17
                      })
                      .style('stroke', 'white')
                      .style('stroke-width', '3px')
                      .style('fill', 'black')
                      .style('display', 'none')

      window.maxText = chartWrapper.append('text')
                      .classed('orientation', true)
                      .attr({
                        x : window.x(new Date(window.xdata[window.xdata.length - 30].date)),
                        y : window.y(yExtentMax[1])
                      })
                      .text(yExtentMax[1].toPrecision(2) + " " + chartFunction.wavePre(this.state.params.wave))
                      .style('fill', 'white')
                      .style('font-weight', '900')
                      .style('display', 'none')

      // window.minBox = chartWrapper.append('rect')
      //                 .classed('orientation', true)
      //                 .attr('class', 'minmaxbox')
      //                 .attr('width', '50px')
      //                 .attr('height', '25px')
      //                 .attr({
      //                   x : window.x(new Date(window.xdata[window.xdata.length - 18].date)),
      //                   y : window.y(yExtentMin[0]) - 10
      //                 })
      //                 .style('stroke', 'white')
      //                 .style('stroke-width', '3px')
      //                 .style('fill', 'black')
      //                 .style('display', 'none')
      //
      // window.minText = chartWrapper.append('text')
      //                 .classed('orientation', true)
      //                 .attr({
      //                   x : window.x(new Date(window.xdata[window.xdata.length - 17].date)),
      //                   y : window.y(yExtentMin[0]) + 8
      //                 })
      //                 .text(yExtentMin[0].toPrecision(2))
      //                 .style('fill', 'white')
      //                 .style('font-weight', '900')
      //                 .style('display', 'none')


      window.locline = chartWrapper.append('line')
                      .classed('orientation', true)
                      .style('display', 'none')
                      .attr('stroke', 'white')
                      .attr('stroke-width', '3px')
                      .attr('x1', window.x(new Date(centerOfData.date)))
                      .attr('x2', window.x(new Date(centerOfData.date)))
                      .attr('y1', window.y(centerOfData.hsbro) + 8)
                      .attr('y2', height)

      if (window.xdata[0].broeqnoaa) {

      } else {
        window.loclineNoaa = chartWrapper.append('line')
                        .classed('orientation', true)
                        .style('display', 'none')
                        .attr('stroke', 'white')
                        .attr('stroke-width', '3px')
                        .attr('x1', window.x(new Date(centerOfData.date)))
                        .attr('x2', window.x(new Date(centerOfData.date)))
                        .attr('y1', window.y(centerOfData.hsnoaa) + 8)
                        .attr('y2', window.y(centerOfData.hsbro) - 8)
      }


      // var drag = d3.behavior.drag()
      //                 .origin(function(d) { return {x: d3.event.clientX, y: d3.event.clientX}; })
      //                 .on("drag", dragMove);

      // window.locator.attr({
      //         cx : window.x(new Date(window.xdata[Math.round(window.xdata.length/2)].date)),
      //         cy : window.y(window.xdata[Math.round(window.xdata.length/2)].hsbro)
      //       })
      //       .style('display', 'block') //.call(drag);
      // var mc = new Hammer.Manager(chartWrapper[0][0]);
      // mc.add(new Hammer.Pan());
      //
      //
      chartWrapper.on('touchmove', this.onTouchMove)
      chartWrapper.on('touchend', function (event) {
        chartFunction.outOfChart();
        
        return true;
      });
      svg.on('mousemove', this.onTouchMove);
      svg.on('mouseleave', function (event) {
        chartFunction.outOfChart();
        
        return true;
      });

      // chartWrapper.on('load', function (event) {
      //   debugger;
      //   outOfChart()
      // });

      // this.transition();

    }
  }

  // transition() {
  //   window.locator.transition()
  //       .duration(10000)
  //       .attrTween("transform", this.translateAlong(window.path.node()))
  //       .each("end", this.transition);
  // }

  // // Returns an attrTween for translating along the specified path element.
  // translateAlong(path) {
  //   var l = path.getTotalLength();
  //   return function(d, i, a) {
  //     return function(t) {
  //       var p = path.getPointAtLength(t * l);
  //       return "translate(" + p.x + "," + p.y + ")";
  //     };
  //   };
  // }


  changeWindowOrientation() {
    d3.select(".line").remove();
    d3.select(".line-noaa").remove();
    d3.select(".minmaxbox").remove();
    d3.select(".line-max").remove();
    d3.select(".line-min").remove();
    d3.selectAll(".orientation").remove();
    window.fetchThis.setState({
      width: window.fetchThis.updateDimensions(window.innerWidth, window.innerHeight)[0],
      height: window.fetchThis.updateDimensions(window.innerWidth, window.innerHeight)[1]
    })
    window.fetchThis.onTouchMove(window.x(Date.now()))
  }

  // Wind by Alex Chocron from the Noun Project
  // waves by Acid Beast from the Noun Project
  render() {

    if (window.innerWidth < window.innerHeight) {
      var paramAdCoef = 0.68;
    } else {
      var paramAdCoef = 0.85;
    }

    return(
        <div>
            <div className='App-header'>
              <HeaderData data={this.state.hourData} spotdata={this.state.data} params={this.state.params}/>
            </div>
            <svg className="graph" ref={node => this.node = node} width={this.state.width} height={this.state.height * paramAdCoef}>
            </svg>
            <div className='App-footer'>
              <BroouAd params={this.state.params}/>
            </div>
        </div>
    );
  }
}
export default LineChart

window.bra = {
  "decimal": ",",
  "thousands": ".",
  "grouping": [3],
  "currency": ["$", ""],
  "dateTime": "%a %b %e %X %Y",
  "date": "%m/%d/%Y",
  "time": "%H:%M:%S",
  "periods": ["AM", "PM"],
  "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  "shortDays": ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
}

window.en = {
  "decimal": ",",
  "thousands": ".",
  "grouping": [3],
  "currency": ["$", ""],
  "dateTime": "%a %b %e %X %Y",
  "date": "%m/%d/%Y",
  "time": "%H:%M:%S",
  "periods": ["AM", "PM"],
  "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  "shortDays": ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
  "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
}
