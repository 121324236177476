import React, { Component } from 'react'
import './style/header.css'
import I18n from "i18n-js";
import * as d3 from "d3-3";
import * as chartFunction from './chartFunctions.js';
// import * as Hammer from "hammerjs";

class HeaderData extends Component {
  componentDidMount(){
    this.createHeader()
  }

  componentDidUpdate(){
    this.createHeader()
  }

  createHeader() {
    // debugger;
    // console.log("hour data = " + this.state.data.date)
    const svg = d3.selectAll("svg.header"),
        width = +svg.attr("width"),
        height = +svg.attr("height");

    var x = d3.time.scale().domain([0, width]);
    var y = d3.scale.linear().domain([height, 0]);
    x.range([0, width]);
    y.range([height, 0]);

    var chartWrapper = svg.append('g');
    var chartWrapper = svg.append('g');

    var circle = chartWrapper.append('circle')
                            .style('display', 'block')
                            .attr('r', 14)
                            .attr('fill', 'transparent')
                            .attr('stroke', 'white')
                            .attr('stroke-width', '3px')
                            .attr({
                              cx: '50%',
                              cy: '50%'
                            })

    var circle2 = chartWrapper.append('circle')
                            .style('display', 'block')
                            .attr('r', 4)
                            .attr('fill', 'rgb(31, 30, 30)')
                            .attr('stroke', 'white')
                            .attr('stroke-width', '2px')
                            .attr({
                              cx: '50%',
                              cy: '20%'
                            })


  }

  updateDimensions(winWidth, winHeight) {
    window.margin = {};
    window.margin.top = 0;
    window.margin.right = 0;
    window.margin.left = 0;
    window.margin.bottom = 0;

    // debugger;
    window.addEventListener("orientationchange", function(event){
      switch(window.orientation) {
        case -90 || 90:
          // console.log('landscape');
          window.width = winWidth - window.margin.left - window.margin.right;
          window.height = winHeight - window.margin.top - window.margin.bottom;
          break;
        default:
          // console.log('portrait');
          window.height = winWidth - window.margin.left - window.margin.right;
          window.width = winHeight - window.margin.top - window.margin.bottom;
          break;
      }
    });

    window.width = winWidth - window.margin.left - window.margin.right;
    window.height = winHeight - window.margin.top - window.margin.bottom;

    // var height = winWidth - window.margin.left - window.margin.right;
    // var width = winHeight - window.margin.top - window.margin.bottom;

    // console.log("width = " + width)
    // console.log("height = " + height)
    return [window.width, window.height]
  }

  render() {
    // console.log("Primary Height = " + window.innerHeight)
    // console.log("Primary Width = " + window.innerWidth)
    var width = this.updateDimensions(window.innerWidth, window.innerHeight)[0];
    var height = this.updateDimensions(window.innerWidth, window.innerHeight)[1];

    if (this.props.spotdata.forecast) {
      if (this.props.spotdata.forecast.isequal) {
        var wave = (
          <div className='wave hsnoaa'>
            <h4><span className="name">noaa</span></h4><h4><span className="hs"> --</span> {chartFunction.wavePre(this.props.params.wave)} </h4>
          </div>
        )
      } else {
        var wave = (
          <div className='wave-data-bro-noaa'>
            <div className='wave hsnoaa'>
              <h4><span className="name">noaa</span></h4><h4><span className="hs"> --</span> {chartFunction.wavePre(this.props.params.wave)}</h4>
            </div>
            <div className='wave hsbro'>
              <h4><span className="name">broou</span></h4><h4><span className="hs"> --</span> {chartFunction.wavePre(this.props.params.wave)}</h4>
            </div>
          </div>
        )
      }
    } else {
    var wave = (
        <div className='wave'>
          <h4><span className="name"> --- </span></h4><h4><span className="hs"> --</span> {chartFunction.wavePre(this.props.params.wave)}</h4>
        </div>
      )
    }

    return(
        <div className="data">
          <div className='wave-size'>
            <div className="wave-logo"></div>
            { wave }
          </div>
          <div className='wave dir'>
            <div className='svg'>
              <span className="dir">S</span>
              <img src="../Fill_3_onda.svg"/>
              <svg className="header" id="wavedir" width={48} height={48}>
              </svg>
            </div>
            <h4><span className="sec wave-sec">--</span> {I18n.t('period')}</h4>
          </div>
          <div className='wind dir'>
            <div className='svg'>
              <span className="dir-wind">S</span>
              <img className='dir-wind' src="../wind_white.svg"/>
              <svg className="header" id="winddir" width={48} height={48}>
              </svg>
            </div>
            <h4><span className="sec">--</span> {chartFunction.windPre(this.props.params.wind)}</h4>
          </div>
        </div>
    );
  }
}
export default HeaderData
