import I18n from "i18n-js";

I18n.defaultLocale = 'pt-BR'
I18n.locale = 'pt-BR'

I18n.translations["en"] = {
  period: 'sec',
  wave_title: "height",
  wave_period: "period (s)",
  wwDir: {
    n: 'N',
    ne: 'NE',
    nw: 'NW',
    e: 'E',
    w: 'W',
    s: 'S',
    sw: 'SW',
    se: 'SE'
  }
}

I18n.translations["pt-BR"] = {
  period: 'seg',
  wave_title: "alt. onda",
  wave_period: "periodo (s)",
  wwDir: {
    n: 'N',
    ne: 'NE',
    nw: 'NO',
    e: 'E',
    w: 'O',
    s: 'S',
    sw: 'SO',
    se: 'SE'
  }
}
