import React from 'react';
import './style/App.css';
import LineChart from './lineChart';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

const App = () => (
  <Router>
    <div className='App'>
      <Route exact path="/:loc/:id" component={LineChart}/>
      <Route exact path="/:id" component={LineChart}/>
    </div>
  </Router>
)

export default App
