import * as d3 from "d3-3";
import I18n from "i18n-js";

export function outOfChart () {
  window.waveHeight
        .transition()
        .style('opacity', '0')
        .duration(400)      
  window.wavePer
        .transition()
        .style('opacity', '0')
        .duration(400)      
  window.tooltip.style('display', 'none');
  window.tooltipText.style('display', 'none');
  window.tooltipTri.style('display', 'none');
  window.locline.style('display', 'none');
  // window.minBox
  //       .transition()
  //       .style('opacity', '1')
  //       .duration(400).style('display', 'block')
  // window.minText
  //       .transition()
  //       .style('opacity', '1')
  //       .duration(400).style('display', 'block')
  window.maxBox
        .transition()
        .style('opacity', '1')
        .duration(400).style('display', 'block')
  window.maxText
        .transition()
        .style('opacity', '1')
        .duration(400).style('display', 'block')
  // d3.select('.line-min')
  //       .transition()
  //       .style('opacity', '1')
  //       .duration(400).style('display', 'none')
  d3.select('.line-max')
        .transition()
        .style('opacity', '1')
        .duration(400).style('display', 'block')
  
  return true;
}

export function range (start, end, inc) {
  var foo = [];
  for (var i = start; i <= end; i += inc) {
    foo.push(i);
  }
  return foo;
}

export function getTextDir (dir) {
  if (dir >= (360 - 27.5) || dir < 27.5) {
    return I18n.t('wwDir.n')
  } else if (dir >= 45 - 27.5 && dir < 45 + 27.5 ) {
    return I18n.t('wwDir.ne')
  } else if (dir >= 90 - 27.5 && dir < 90 + 27.5) {
    return I18n.t('wwDir.e')
  } else if (dir >= 135 - 27.5 && dir < 135 + 27.5) {
    return I18n.t('wwDir.se')
  } else if (dir >= 180 - 27.5 && dir < 180 + 27.5) {
    return I18n.t('wwDir.s')
  } else if (dir >= 225 - 27.5 && dir < 225 + 27.5) {
    return I18n.t('wwDir.sw')
  } else if (dir >= 270 - 27.5 && dir < 270 + 27.5) {
    return I18n.t('wwDir.w')
  } else if (dir >= 315 - 27.5 && dir < 315 + 27.5) {
    return I18n.t('wwDir.nw')
  }
}

export function waveType (type, wnd) {
  if (type == 'ft') {
    return wnd / 0.33
  }
  return wnd
}
export function wavePre (type) {
  return (type == 'ft') ? 'ft' : 'm'
}

export function windType (type, wnd) {
  if (type == 'mph') {
    return wnd * 0.6
  }
  if (type == 'knots') {
    return wnd * 0.5
  }
  return wnd
}
export function windPre (type) {
  if (type == 'mph') {
    return type
  }
  if (type == 'knots') {
    return type
  }
  return 'km/h'
}

window.addEventListener("load", function(event) {
  setTimeout(function () {
    if (window.fetchThis.props.match.params.id !== "index.html") {
      // debugger
      window.fetchThis.onTouchMove(window.x(Date.now()))
    }
  }, 1500)

  return true;
});

window.addEventListener("orientationchange", function(e) {
  setTimeout(window.fetchThis.changeWindowOrientation, 200)
  
  return true;
});
